import React, { useState } from 'react'
import './App.css'
import Buttons from './GridButtons';
import PageContainer from './pages/PageContainer';
import RightMenu from './RightMenu';

function App() {

  const poweredByDiv = document.querySelector('div[style="text-align:right;position:fixed;z-index:9999999;bottom:0;width:auto;right:1%;cursor:pointer;line-height:0;display:block!important"]');
  if (poweredByDiv) {
    poweredByDiv.style.display = 'none';
  }

  const [ menuStyle, setMenuStyle ] = useState("0")
  const [ buttonsStyle, setButtonsStyle ] = useState("0")
  const [ showBackButton, setShowBackButton ] = useState(false)
  const [ pageStyle, setPageStyle ] = useState("100vw")

  const [ index, setIndex ] = useState(null)

  const handleClickButton = e => {
    setButtonsStyle("100vw")
    setMenuStyle("-166px")
    setPageStyle("0")
    setShowBackButton(true)
    setIndex(Number(e.currentTarget.id))
}

  const backToMenu = () => {
    setButtonsStyle("0vw")
    setMenuStyle("0")
    setPageStyle("100vw")
    setShowBackButton(false)
    setIndex(null)
  }

  const learnStates = e => {
    setButtonsStyle("100vw")
    setMenuStyle("-166px")
    setPageStyle("-100vw")
    setShowBackButton(true)
    //setIndex(Number(e.currentTarget.id))
}

  return (
    <div className="App">
      <img className='mainBackground' src='mainBackground.jpg' alt='Background'/>
      
      <Buttons buttonsStyle={buttonsStyle} handleClickButton={handleClickButton} />
      <RightMenu menuStyle={menuStyle}/>
      <PageContainer showBackButton={showBackButton} backToMenu={backToMenu} index={index} pageStyle={pageStyle} learnStates={learnStates}/>

      <footer>LMS diseñado por educadores. Pedagógicamente neutro.</footer>
    </div>
  );
}

export default App;

/* <div className="App">
      <img className='mainBackground' src='mainBackground.jpg' alt='Background'/>
      <div class="grid-container">
        <button class="grid-button a"><img src='/menu-buttons/CRM1.png' alt='Button Icon'/></button>
        <button class="grid-button b"><img src='/menu-buttons/CRM3.png' alt='Button Icon'/></button>
        <button class="grid-button c"><img src='/menu-buttons/CRM2a.png' alt='Button Icon'/></button>
        <button class="grid-button d"><img src='/menu-buttons/CRM2b.png' alt='Button Icon'/></button>
        <button class="grid-button e"><img src='/menu-buttons/CRM5.png' alt='Button Icon'/></button>
        <button class="grid-button f"><img src='/menu-buttons/CMS1.png' alt='Button Icon'/></button>
        <button class="grid-button g"><img src='/menu-buttons/CMS2.png' alt='Button Icon'/></button>
        <button class="grid-button h"><img src='/menu-buttons/CMS3.png' alt='Button Icon'/></button>
        <button class="grid-button i"><img src='/menu-buttons/CMS4.png' alt='Button Icon'/></button>
        <button class="grid-button j"><img src='/menu-buttons/CMS6.png' alt='Button Icon'/></button>
        <button class="grid-button k"><img src='/menu-buttons/CMS11.png' alt='Button Icon'/></button>
        <button class="grid-button l"><img src='/menu-buttons/CMS10.png' alt='Button Icon'/></button>
        <button class="grid-button m"><img src='/menu-buttons/CMS9.png' alt='Button Icon'/></button>
        <button class="grid-button n"><img src='/menu-buttons/CRM1.png' alt='Button Icon'/></button>
        <button class="grid-button 0"><img src='/menu-buttons/CRM1.png' alt='Button Icon'/></button>
      </div>
      <footer>LMS diseñado por educadores. Pedagógicamente neutro. EDUCACIÓN ADAPTADA A CADA ALUMNO</footer>
    </div> */
