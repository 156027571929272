import './GridButtons.css'
import data from './data.json'

const Buttons = (props) => {

  return (
    <div className="grid-container" style={{right: `${props.buttonsStyle}`}}>
      <img className='mainLogo' src='mainLogo.png' alt='Background'/>
        {data.gridButtons.map( (el, index) =>
          el.doubleSize?
          <div className='grid-button doubleSize' key={index+20}>
            <span>Modelo tradicional</span>
            <span id="14" onClick={props.handleClickButton}>Modelo conductista</span>
            <span>Modelo experiencial-romántico</span>
            <span>Modelo cognitivista-desarrollista</span>
            <span id="15" onClick={props.handleClickButton}>Modelo constructivista</span>
          </div>
          :
          <button key={index} id={index} className="grid-button" style={{background: `${el.color}`}} onClick={data.gridButtons[index].images.length !== 0? props.handleClickButton : null}>
            <img src={`/grid-buttons/${el.icon}.png`} alt='Button Icon'/>
            <span>{el.title}</span>
          </button>
        )}
    </div>
  )
}

export default Buttons