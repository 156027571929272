import React from 'react'
import './RightMenu.css'
import data from './data.json'

const RightMenu = (props) => {
  return (
    <div className='rightMenu-container' style={{right: `${props.menuStyle}`}}>
        <img className='menuLogo' src='sarmientechLogo.png' alt='Logo Icon'/>
        <div className='menuCards'>
            {data.menuButtons.map( (el, index) => 
                <button className='menuCard' key={index} style={{background: `${el.color}`}}>
                    <img src={`/menu-buttons/${el.icon}.png`} alt='Button Icon'/>
                    <span></span>
                </button>
            )}
        </div>
        <div className="settings-container">
          <div className='setting'>
            <i className="fa-solid fa-gear"></i>
            <h4>Ajustes</h4>
          </div>
          <div className='setting'>
            <i className="fa-solid fa-right-from-bracket"></i>
            <h4>Salir</h4>
          </div>
        </div>
    </div>
  )
}

export default RightMenu