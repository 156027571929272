import React from 'react'
import './PageContainer.css'
import data from '../data.json'

const PageContainer = (props) => {

  const button = data.gridButtons[props.index]

  return (
    <div className='page-container' style={{transform: `translateX(${props.pageStyle})`}}>
        <h2 className={props.showBackButton?'page-title active' : 'page-title'}>
          {props.index === 14?
            "MODELO CONDUCTISTA"
            :
            props.index === 15?
            "MODELO CONSTRUCTIVISTA"
            :
            data.gridButtons[Number(props.index)]?.title
          }
          </h2>
        {button?.title === "Machine Learning"?
          <>
            <img className="page-image" src="/grid-buttons/machinelearning.png" alt='Button Icon'/>
            <button onClick={props.learnStates} className="moreInformation-btn">Ampliar información</button>
            <div className='learnStates'>
              <h2 className={props.showBackButton?'page-title active' : 'page-title'}>ESTADO DEL APRENDIZAJE Y SUGERENCIAS DE REVISIÓN</h2>
              <img className="page-image" src="/grid-buttons/estadodelaprendizaje.png" alt='Button Icon'/>
            </div>
          </>
          :
          button?.title === "Escuela y Clases"?
          <>
            <img className="page-image" src="/grid-buttons/escuelayclases.png" alt='Button Icon'/>
            <button onClick={props.learnStates} className="moreInformation-btn school">Ampliar información</button>
            <div className='learnStates'>
              <h2 className={props.showBackButton?'page-title active' : 'page-title'}>ESTADO DEL APRENDIZAJE Y SUGERENCIAS DE REVISIÓN</h2>
              <img className="page-image" src="/grid-buttons/TABLAALUMNOS.png" alt='Button Icon'/>
            </div>
          </>
          :
          props.index === 14? 
            <img className="page-image" src="/grid-buttons/conductismo.png" alt='Button Icon'/>
          :
          props.index === 15? 
            <img className="page-image" src="/grid-buttons/constructivismo.png" alt='Button Icon'/>
          :
          button?.images.map((el, index) => 
            <img className="page-image" src={`/grid-buttons/${el}`} alt='Button Icon' key={index}/>
          )  
        }
        <button className={props.showBackButton? 'backMenu-btn active' : 'backMenu-btn'} onClick={props.backToMenu}>Volver</button>
        <button className={props.showBackButton? 'backMenu-btn2 active' : 'backMenu-btn2'} onClick={props.backToMenu}>Volver</button>
    </div>
  )
}

export default PageContainer

/* <div className='page-container' style={{transform: `translateX(${props.pageStyle})`}}>
        <h2 className={props.showBackButton?'page-title active' : 'page-title'}>{data.gridButtons[Number(props.index)]?.title}</h2>
        {props.index && data.gridButtons[Number(props.index)].images.map(el => 
            <img className="page-image" src={`/grid-buttons/${el}`} alt='Button Icon'/>
        )}
        <button className={props.showBackButton? 'backMenu-btn active' : 'backMenu-btn'} onClick={props.backToMenu}>Volver</button>
    </div> */